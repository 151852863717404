import React, { useEffect, useContext } from "react";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import ApprelyLogoIcon from "../assets/images/logo/apprely-logo.svg";
import HumburgerIcon from "../assets/images/Icons/humburger-icon.svg";

export default function Nav({ activeItem, setActiveItem, navComponents }) {
  const location = useLocation();
  const navComponentsSomeArray = navComponents?.slice(0,5);

  const navItems = [
    { name: "About Us", route: "/aboutus" },
    {
      name: "Capability",
      children: [
        { name: "Integration", route: "/capability/integration" },
        { name: "RPA", route: "/capability/rpa" },
        { name: "CTRM", route: "/capability/ctrm" },
        { name: "monday.com", route: "/capability/monday-partner" },
        { name: "msd-services", route: "/capability/msd-services" },
      ],
    },
    { name: "Solutions", route: "/solutions" },
    { name: "Case Study", route: "/blogs" },
    { name: "Careers", route: "/careers" },
    { name: "Contact Us", route: "/contactus" },
  ];

  const handleItemClick = (item) => {
    setActiveItem(item);
  };

  const handleLogoClick = (item) => {
    setActiveItem(item);
  };

  // Synchronize activeItem with the current URL path
  useEffect(() => {
    const currentPath = location.pathname;
    const trimmedPath = currentPath.endsWith('/') ? currentPath.slice(0, -1) : currentPath;
  
    let matchedItem;
    if (trimmedPath == "/capability/rpa" ||
      trimmedPath == "/capability/ctrm" ||
      trimmedPath == "/capability/monday-partner" ||
      trimmedPath == "/capability/integration" ||
      trimmedPath == "/capability/msd-services") {
      matchedItem = navItems[0]?.children?.find(
        (item) => item.route == trimmedPath
      );    
    } else {
      matchedItem = navItems.find(
        (item) => item.route == trimmedPath
      );
    }
    if (matchedItem) {
      setActiveItem(matchedItem.route);
    }
  }, [location.pathname, navItems, navComponentsSomeArray, setActiveItem]);

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light ap__navbar">
        <div className="container-fluid">
          <Link
            to="/"
            onClick={() => handleLogoClick("/")}
            className="navbar-brand"
          >
            <img
              src={ApprelyLogoIcon}
              alt="AppRely-logo"
              className="logo"
              height="100"
            />
          </Link>
          <button
            style={{ border: 0 }}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <span style={{
              color: 'white',
              fontFamily: 'var(--unnamed-font-MontserratLightBold)',
              fontWeight: '700',
              fontSize: '14px',
              marginRight: '10px',
            }}>Menu</span> */}
            <img src={HumburgerIcon} alt={HumburgerIcon} />
            {/* <span className="navbar-toggler-icon"></span> */}
          </button>
          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto">
              {navItems.map((item, index) => {
                return (
                  <>
                    {item.name == "Capability" && (
                      <>
                          <li className="dropdown">
                            <a
                              className={
                                activeItem === item?.children?.route
                                  ? "nav-link dropdown-toggle"
                                  : "nav-link dropdown-toggle"
                              }
                              href="#"
                              role="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Capability
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                              style={{
                                listStyleImage: "none",
                                listStyleType: "none",
                              }}
                            >
                              <div className="dropwdown-top-angle__arrow"></div>
                              {item?.children?.map((subitem, index) => {
                                return (
                                  <Link
                                    className="dropdown-item"
                                    aria-current="page"
                                    to={subitem.route}
                                    onClick={() => handleItemClick(subitem.route)}
                                  >
                                    <li
                                      onClick={() => handleItemClick(subitem.route)}
                                      data-bs-toggle="collapse"
                                      data-bs-target=".navbar-collapse.show"
                                    >
                                      <span
                                        className={
                                          activeItem === subitem.route && "active"
                                        }
                                      >
                                        {subitem.name}
                                      </span>
                                    </li>
                                  </Link>
                                )
                              })}
                            </ul>
                          </li>
                      </>
                    )}

                    {item.name != "Capability" && item.name != "Contact Us" && ( 
                      <li
                        className="nav-item"
                        onClick={() => handleItemClick(`${item.label}`)}
                      >
                        <Link
                          className="nav-link"
                          aria-current="page"
                          to={`${item.route}`}
                        >
                          <span
                            className={
                              activeItem === `${item.route}`
                                ? "nav-item active"
                                : "nav-item"
                            }
                            data-bs-toggle="collapse"
                            data-bs-target=".navbar-collapse.show"
                          >
                            {item.name}
                          </span>
                        </Link>
                      </li>
                    )}

                    {item.name == "Contact Us" && ( 
                      <Link
                        to="/contactus"
                        onClick={() => handleItemClick(`${item.label}`)}
                        className="btn btn__primary-bg btn--w-155 ap-fw__700"
                      >
                        <span
                          className="nav-item"
                          data-bs-toggle="collapse"
                          data-bs-target=".navbar-collapse.show"
                        >
                          Contact us
                        </span>
                      </Link>
                    )}
                  </>
                )
              })}
              <div className="d-none">
                {(navComponentsSomeArray || []).map((item) => {
                  return (
                    <>  
                      {item.label !== "Contact Us" ? (
                        <>
                          <li
                            className="nav-item"
                            onClick={() => handleItemClick(`${item.label}`)}
                          >
                            <Link
                              className="nav-link"
                              aria-current="page"
                              to={`${item.route}`}
                            >
                              <span
                                className={
                                  activeItem === `${item.route}`
                                    ? "nav-item active"
                                    : "nav-item"
                                }
                                data-bs-toggle="collapse"
                                data-bs-target=".navbar-collapse.show"
                              >
                                {item.label}
                              </span>
                            </Link>
                          </li>
                        </>
                      ) : (
                        <>
                          <li className="dropdown">
                            <a
                              className={
                                activeItem === `/integration` ||
                                activeItem === `/rpa`
                                  ? "nav-link dropdown-toggle"
                                  : "nav-link dropdown-toggle"
                              }
                              href="#"
                              role="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Capability
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                              style={{
                                listStyleImage: "none",
                                listStyleType: "none",
                              }}
                            >
                              <div className="dropwdown-top-angle__arrow"></div>
                              <Link
                                className="dropdown-item"
                                aria-current="page"
                                to={"/integration"}
                                onClick={() => handleItemClick(`/integration`)}
                              >
                                <li
                                  onClick={() => handleItemClick(`/integration`)}
                                  data-bs-toggle="collapse"
                                  data-bs-target=".navbar-collapse.show"
                                >
                                  <span
                                    className={
                                      activeItem === `/integration` && "active"
                                    }
                                  >
                                    Integration
                                  </span>
                                </li>
                              </Link>
                              <Link
                                className="dropdown-item"
                                aria-current="page"
                                to={"/rpa"}
                                onClick={() => handleItemClick(`/rpa`)}
                              >
                                <li
                                  data-bs-toggle="collapse"
                                  data-bs-target=".navbar-collapse.show"
                                >
                                  <span
                                    className={activeItem === `/rpa` && "active"}
                                  >
                                    RPA Bot
                                  </span>
                                </li>
                              </Link>
                              <Link
                                className="dropdown-item"
                                aria-current="page"
                                to={"/ctrm"}
                                onClick={() => handleItemClick(`/ctrm`)}
                              >
                                <li
                                  data-bs-toggle="collapse"
                                  data-bs-target=".navbar-collapse.show"
                                >
                                  <span
                                    className={activeItem === "/ctrm" && "active"}
                                  >
                                    CTRM
                                  </span>
                                </li>
                              </Link>
                              <Link
                                className="dropdown-item"
                                aria-current="page"
                                to={"/monday-partner"}
                                onClick={() => handleItemClick(`/monday-partner`)}
                              >
                                <li
                                  data-bs-toggle="collapse"
                                  data-bs-target=".navbar-collapse.show"
                                >
                                  <span
                                    className={
                                      activeItem === `/monday-partner` && "active"
                                    }
                                  >
                                    monday.com
                                  </span>
                                </li>
                              </Link>
                              <Link
                                className="dropdown-item"
                                aria-current="page"
                                to={"/msd-services"}
                                onClick={() => handleItemClick(`/msd-services`)}
                              >
                                <li
                                  data-bs-toggle="collapse"
                                  data-bs-target=".navbar-collapse.show"
                                >
                                  <span
                                    className={
                                      activeItem === `/msd-services` && "active"
                                    }
                                  >
                                    MSD Services
                                  </span>
                                </li>
                              </Link>
                              {/* <Link 
                                className="dropdown-item" 
                                aria-current="page" 
                                to={'/marketing-page'}
                                onClick={() => handleItemClick(`/marketing-page`)}
                                >
                                <li 
                                  data-bs-toggle="collapse"
                                  data-bs-target=".navbar-collapse.show">
                              
                                  <span
                                    className={activeItem === `/marketing-page` && "active"} 
                                  >monday.com</span>
                                </li>
                              </Link> */}
                              {/* <Link 
                                className="dropdown-item" 
                                aria-current="page" 
                                to={'/gmailConnector'}
                                onClick={() => handleItemClick(`/gmail_connector`)}
                                >
                                <li 
                                  data-bs-toggle="collapse"
                                  data-bs-target=".navbar-collapse.show">
                              
                                  <span
                                    className={activeItem === `/gmail_connector` && "active"} 
                                  >Gmail Connector</span>
                                </li>
                              </Link> */}
                            </ul>
                          </li>
                          <Link
                            to="/contactus"
                            onClick={() => handleItemClick(`${item.label}`)}
                            className="btn btn__primary-bg btn--w-155 ap-fw__700"
                          >
                            <span
                              className="nav-item"
                              data-bs-toggle="collapse"
                              data-bs-target=".navbar-collapse.show"
                            >
                              Contact us
                            </span>
                          </Link>
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}