import React from "react";
import { Link } from "gatsby";

import InIcon from "../assets/images/icons/in-icon.svg";
import ApprelyLogoIcon from "../assets/images/logo/apprely-logo.svg";
import InstaIcon from "../assets/images/icons/insta-icon.svg";
import FBIcon from "../assets/images/icons/fb-icon.svg";


export default function Footer({ setActiveItem, navComponents }) {
  const navComponentsSomeArray = navComponents?.slice(0, 3);
  return (
    <footer className="footer__section">
      <div className="container p-0">
        <div className="footer__description">
          <div className="row m-0">
            <div className="col-12 col-md-5">
              <div className="">
                <img
                  src={ApprelyLogoIcon}
                  alt="AppRely logo"
                  className="logo"
                  height="150"
                  style={{ marginTop: '-50px', marginLeft: '-25px' }}
                />
                <div className="title">
                  <p>AppRely Technologies crafts transformative software <br/> 
                  solutions, enabling enterprises to optimize workflows and <br/>  
                  realize strategic objectives.</p>
                  {/* <p>AppRely Technologies delivers custom software solutions,  <br/> including
                  web and mobile apps, system integrations, and<br/> CTRM systems.  
                  Focused on innovation and scalability, they <br/> help businesses streamline processes and
                  achieve their<br/> goals through advanced technology.
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-2 footer-links">
              <h4>
                Company
              </h4>
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/aboutus">About Us</Link></li>
                <li><Link to="/solutions">Solutions</Link></li>
                <li><Link to="/careers">Careers</Link></li>
                <li><Link to="/privacyPolicy">Privacy Policy</Link></li>
                <li><Link to="/contactus">Contact Us</Link></li>
              </ul>
            </div>  
            <div className="col-12 col-md-2 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li><Link to="/capability/integration/">Integration</Link></li>
                <li><Link to="/capability/rpa/">RPA</Link></li>
                <li><Link to="/capability/ctrm/">CTRM</Link></li>
                <li><Link to="/capability/monday-partner/">monday.com</Link></li>
                <li><Link to="/capability/msd-services/">msd-services</Link></li>
              </ul>
            </div>  
            <div className="col-12 col-md-3 footer-links">
              <h4>Contact Us</h4>
              <p>3rd Floor, Ghule Square,</p>
              <p>Bavdhan, Pune,</p>
              <p>Maharashtra 411021</p>
              <p className="mt-20"><strong>EMAIL:</strong> <span>info@apprely.com</span></p>
              <p><strong>WEB:</strong> <span>www.apprely.com</span></p>

              <div className="sub-footer-links">
                <strong>FOLLOW US ON:</strong>
                <div className="footer-social__links">
                  <ul className="site-links">
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/apprely-technologies/"
                        target="_blank"
                      >
                        <img src={InIcon} alt="AppRely Technologies LinkedIn Profile Link" className="spacing" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://instagram.com/apprely_technologies?igshid=MzRlODBiNWFlZA==" target="_blank">
                        <img src={InstaIcon} alt={InstaIcon} className="spacing" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>  
            <div className="d-none">
              <div>
                <div className="right-side__content">
                  <p className="right-title">
                    <ul>
                      {(navComponentsSomeArray || []).map((item) => {
                        return (
                          <>
                            <li onClick={() => setActiveItem(`${item.label}`)}>
                              <Link to={`${item.route}`}>
                                <span>{item.label}</span>
                              </Link>
                            </li>
                            <li style={{ color: "#525252" }}>|</li>
                          </>
                        );
                      })}
                      <li onClick={() => setActiveItem(`/privacyPolicy`)}>
                        <Link to="/privacyPolicy">
                          <span>Privacy Policy</span>
                        </Link>
                      </li>
                      <li style={{ color: "#525252" }}>|</li>
                    </ul>
                  </p>

                  <ul className="site-links">
                    <li>
                      <Link
                        to="https://www.linkedin.com/company/apprely-technologies/"
                        target="_blank"
                      >
                        <img src={InIcon} alt="AppRely Technologies LinkedIn Profile Link" className="spacing" />
                      </Link>
                    </li>
                    {/*<li>
                      <Link to="https://instagram.com/apprely_technologies?igshid=MzRlODBiNWFlZA==" alt="AppRely Technologies Instagram Profile Link" target="_blank">
                        <img src={InstaIcon} alt={InstaIcon} className="spacing" />
                      </Link>
                    </li>*/}
                    {/*<li>
                      <Link to="https://www.facebook.com/apprely/" alt="AppRely Technologies Facebook Profile Link" target="_blank">
                        <img src={FBIcon} alt={FBIcon} className="spacing" />
                      </Link>
                    </li>*/}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="left-side__content">
            <p className="left-title">Trusted source in IT <br /> services & support</p>
            <p className="left-subtitle" style={{ fontSize: '12px', lineHeight: '20px', color: '#DDDDDD' }}>Empowering your digital transformation with innovative IT solutions.<br /> Your gateway to a seamless and secure digital future. </p>
          </div>
          <div className="right-side__content">
            <p className="right-title">
              <ul>
                {(navComponentsSomeArray || []).map((item) => {
                  return (
                    <>
                      <li onClick={() => setActiveItem(`${item.label}`)}>
                        <Link 
                          to={`${item.route}`}
                          >
                          <span >{item.label}</span>
                        </Link>
                      </li>
                      <li style={{ color: '#525252' }}>|</li>
                    </>
                  )
                })}
                </ul>
            </p>
            <div className="row mt-10 mb-10">
              <div className="col-7 col-md-7">
                <div style={{ fontSize: '12px', lineHeight: '20px', color: '#DDDDDD' }}>
                  India, (Pune & Solapur, MH) <span className="ml-10 mr-10" style={{ color: '#525252' }}>|</span>  USA, Austin,Tx
                </div>
              </div>
              <div className="col-5 col-md-5" style={{ fontSize: '12px', lineHeight: '20px', color: '#DDDDDD' }}>
                <div>info@apprely.com</div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div class="">
                  <Link to="/contactus" className="btn btn__secondary-outline footer-btn btn--w-158 mt-20 ap-fw__700">                
                    Contact us {' '} <img src={ArrowNextIcon} alt={ArrowNextIcon} className="btn__arrow ml-10" />
                  </Link>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <ul className="site-links">
                  <li>
                    <Link to="https://www.linkedin.com/company/apprely-technologies/" target="_blank">
                      <img src={InIcon} alt={InIcon} className="spacing" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://instagram.com/apprely_technologies?igshid=MzRlODBiNWFlZA==" target="_blank">
                      <img src={InstaIcon} alt={InstaIcon} className="spacing" />
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.facebook.com/apprely/" target="_blank">
                      <img src={FBIcon} alt={FBIcon} className="spacing" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="footer">
        <p>Copyright © {new Date().getFullYear()} AppRely Technologies.All rights reserved.</p>
      </div>
    </footer>
  );
}
